import instance from "../configs/binanceApi.config";

class BinanceService {
    _api = instance;

    getTicker = async (symbol) => {
        const response = await this._api.get(`/ticker/price?symbol=${symbol}`);
        return response.data;
    };
}

export default BinanceService;
