import axios from "axios";

const _apiUrl = "https://api.binance.com/api";
const _apiVersion = "v3";

const instance = axios.create({
    baseURL: `${_apiUrl}/${_apiVersion}`,
});

export default instance;
