import "./settingsPage.scss";

import { useState } from "react";

import MainLayout from "../../layouts/mainLayout/MainLayout";
import SettingsBlockItem from "../../settingsBlockItem/SettingsBlockItem";
import IconButton from "../../buttons/iconButton/IconButton";
import SettingsContacts from "../../settingsContacts/SettingsContacts";
import MainButton from "../../buttons/mainButton/MainButton";
import ChangePasswordForm from "../../changePasswordForm/ChangePasswordForm";
import UserContacts from "../../userContacts/UserContacts";

import passwordBgImage from "../../../resources/images/settings/lockImage.png";
import rulesBgImage from "../../../resources/images/settings/attentionImage.png";

import { ReactComponent as LogoutIcon } from "../../../resources/icons/settingsPage/logoutIcon.svg";

import reloadAndClearLocalStorage from "../../../utils/reloadAndClearLocalStorage";

import useShowRulesModal from "../../../hooks/useShowRulesModal";

const SettingsPage = () => {
    const [isPasswordResetOpen, setIsPasswordResetOpen] = useState(false);

    const togglePasswordResetModal = () => {
        setIsPasswordResetOpen((isOpen) => !isOpen);
    };

    const showRulesModal = useShowRulesModal();

    const handleRulesModalOpen = () => {
        showRulesModal();
    };

    return (
        <>
            <MainLayout>
                <div className="settings__wrapper">
                    <div className="settings-paper__wrapper">
                        {/* <Paper className="settings-paper" color="dark">
                            <TextTitle color="gold">Ваши контакты</TextTitle>

                            <div className="settings-contact__wrapper">
                                <InputSelect
                                    name="contactType"
                                    className="settings-contact__select"
                                    placeholder={"Соцсеть"}
                                    options={[
                                        { value: "jabber", label: "Jabber" },
                                        {
                                            value: "telegram",
                                            label: "Telegram",
                                        },
                                    ]}
                                />
                                <AuthInput
                                    type="text"
                                    className="settings-contact__input"
                                    placeholder="Контакт"
                                />
                            </div>
                        </Paper> */}

                        <UserContacts className="settings-paper" />

                        <SettingsContacts className="settings-paper" />
                    </div>
                    <div className="settings-actions__wrapper">
                        <SettingsBlockItem
                            bgImageStyle={{
                                left: "auto",
                                right: "0",
                                transform: "none",
                            }}
                            title="Пароль"
                            bgImage={passwordBgImage}
                        >
                            <MainButton
                                onClick={togglePasswordResetModal}
                                className="settings-actions__button"
                            >
                                Сменить пароль
                            </MainButton>
                        </SettingsBlockItem>
                        <SettingsBlockItem
                            bgImageStyle={{
                                left: "auto",
                                right: "5%",
                                transform: "none",
                            }}
                            title="Правила сервиса"
                            bgImage={rulesBgImage}
                        >
                            <MainButton
                                onClick={handleRulesModalOpen}
                                className="settings-actions__button"
                            >
                                Прочитать
                            </MainButton>
                        </SettingsBlockItem>
                        <IconButton
                            onClick={() => {
                                reloadAndClearLocalStorage();
                            }}
                            className="settings-actions__logout"
                            icon={
                                <LogoutIcon className="settings-actions__logout-icon" />
                            }
                            isSelected
                        >
                            Выйти из учётной записи
                        </IconButton>
                    </div>
                </div>
            </MainLayout>

            <ChangePasswordForm
                handleClose={togglePasswordResetModal}
                isOpen={isPasswordResetOpen}
            />
        </>
    );
};

export default SettingsPage;
