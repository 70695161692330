import "./logo.scss";

import { useRef } from "react";

import { ReactComponent as LogoText } from "../../resources/icons/logo/logoText.svg";
import { ReactComponent as LogoIcon } from "../../resources/icons/logo/logo.svg";

let logoAnimTimeout;

const getPathElems = (generalElem) => {
    if (generalElem.nodeName === "path") {
        return generalElem.parentElement.children;
    } else if (generalElem.children) {
        for (let key in generalElem.children) {
            return getPathElems(generalElem.children[key]);
        }
    }
};

const Logo = () => {
    const logoIconRef = useRef();

    const startAnimation = () => {
        const logo = logoIconRef.current;
        const pathElems = getPathElems(logo);

        pathElems[0].style.transform = "matrix(1,0,0,1,0,2)";

        logoAnimTimeout = setTimeout(() => {
            pathElems[1].style.transform = "matrix(1,0,0,1,0,-2)";
        }, 200);
    };

    const endAnimation = () => {
        clearTimeout(logoAnimTimeout);

        const logo = logoIconRef.current;
        const pathElems = getPathElems(logo);

        pathElems[1].style.transform = "matrix(1,0,0,1,0,0)";

        setTimeout(() => {
            pathElems[0].style.transform = "matrix(1,0,0,1,0,0)";
        }, 200);
    };

    return (
        <div
            onMouseEnter={startAnimation}
            onMouseLeave={endAnimation}
            className="logo"
        >
            <LogoIcon ref={logoIconRef} className="logo__icon" />
            <LogoText />
        </div>
    );
};

export default Logo;
